

import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { DumpForm } from '@/types/devices'

@Component
export default class DumpConfig extends Vue {
  @Prop() readonly deviceId!: string
  @Ref('formData') readonly form!: ElForm

  loading = false

  formData: DumpForm = {
    deviceId: '',
    deviceNumber: '',
    workPattern: '0', // 工作模式：0为绝对模式；1为相对模式；2为混合模式；
    defenseEnable: '1', // 布防使能；范围：0~255，0：撤防，1：布防，默认布防；
    heartbeatIntervalTimeAbsoluteMode: '', // 心跳时间间隔(绝对模式)；取值范围：60~2160000单位:秒
    zeroPatternAbsoluteMode: '0', // 零点模式(绝对模式)；0：绝对角度输出；1：设置当前位置为零点，相对角度输出；其它值无效
    actAlarmAngleAbsoluteMode: '', // 报警角度(绝对模式)；范围：1.0~30.00，默认5.0度单位：度
    collectionIntervalTimeAbsoluteMode: '', // 采集间隔(绝对模式)；范围：0~4294967295，默认60单位：秒(s)
    failureRetransmissionIntervalTimeAbsoluteMode: '', // 失败重发间隔(绝对模式)；范围：0~4294967295，默认3600单位：秒(s)
    heartbeatIntervalTimeRelativeMode: '', // 心跳时间间隔(相对模式)；范围：0~4294967295，默认86400单位：秒(s)
    failureRetransmissionIntervalTimeRelativeMode: '', // 失败重发间隔(相对模式)；范围：0~4294967295，默认3600单位：秒(s)
    actAlarmAngleRelativeMode: '', // 报警角度(相对模式)范围：-360~360，默认5.0度单位：度
    actTimeRelativeMode: '', // 报警触发时间(相对模式)；范围：0~4294967295，默认2单位：秒(s)
    inactAlarmAngleRelativeMode: '', // 静止角度(相对模式)；范围：-360~360，默认2.5度单位：度
    inactTimeRelativeMode: '', // 静止触发时间(相对模式)；范围：0~4294967295，默认2单位：秒(s)
    vibrationActAlarmAngleMixedMode: '', // 振动报警角度(混合模式)；范围：0.06~30.00，默认3.0度单位：度
    actTimeMixedMode: '', // 报警触发时间(混合模式)；范围：80~20000，默认240ms单位：毫秒(ms)
    inactAlarmAngleMixedMode: '', // 静止角度(混合模式)；范围：0.06~30.00，默认1.0度单位：度
    inactTimeMixedMode: '', // 静止触发时间(混合模式)；范围：80~20000，默认240ms单位：毫秒(ms)
    heartbeatIntervalTimeMixedMode: '', // 心跳时间间隔(混合模式)；范围：60~2160000，默认86400单位：秒(s)
    failureRetransmissionIntervalTimeMixedMode: '', // 失败重发间隔(混合模式)；范围：20~2160000，默认3600单位：秒(s)
    zeroPatternMixedMode: '', // 零点模式(混合模式)；范围：0~1，0：绝对角度输出，1：设置当前位置为零点，相对角度输出，其他值无效
    absoluteActAlarmAngleMixedMode: '', // 绝对报警角度(混合模式)；范围：0.06~30.00，默认5.0度；单位：度
    collectionIntervalTimeMixedMode: '' // 采集间隔(混合模式)；范围：1~2160000，默认60单位：秒(s)
  }

  rules = {
  }

  created () {
    this.formData.deviceId = this.deviceId
    this.formData.deviceNumber = this.$route.query.number
    this.loadData()
  }

  loadData () {
    this.$axios.get(this.$apis.devices.selectDumpConfById, {
      deviceId: this.deviceId
    }).then(res => {
      if (res) {
        this.formData = res
      }
    })
  }

  saveData () {
    this.form.validate().then(() => {
      this.loading = true
      this.$axios.post(this.$apis.devices.insertDumpConf, this.formData).then(() => {
        this.$message.success('保存成功')
        this.loadData()
      }).finally(() => {
        this.loading = false
      })
    })
  }
}
