
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { Species, DeviceForm } from '../../types/devices'
import { integerWithin, validNumberOne, validNumberThree, validNumberFour } from '@/utils/validate'
import { DicList } from '../../types/common'

@Component
export default class Config extends Vue {
  @Prop() readonly deviceId!: string;
  @Ref('form') readonly form!: ElForm;

  loading = false
  deviceForm: DeviceForm = {
    objectId: '',
    maintType: '',
    depth: '',
    // excessiveDrought: '',
    drought: '',
    saturated: '',
    frozen: '',
    overheated: '',
    typeStr: '',
    types: [],
    nitrogenUpperLimit: '',
    nitrogenLowerLimit: '',
    phosphorusUpperLimit: '',
    phosphorusLowerLimit: '',
    potassiumUpperLimit: '',
    potassiumLowerLimit: '',
    orpLowerLimit: '',
    orpUpperLimit: ''
  }

  species: Species[] = []
  arr: Array<string> = []

  rules = {
    maintType: [{ required: true, message: '请选择养护物种', trigger: 'change' }], // 养护物种编号
    depth: [{ required: true, message: '请输入距离地面深度', trigger: 'blur' }], // 距离地面深度/CM
    drought: [
      { required: true, message: '请输入土壤湿度干旱阈值', trigger: 'blur' },
      { validator: integerWithin, trigger: ['blur', 'change'] }
    ], // 土壤湿度干旱阈值/%
    saturated: [
      { required: true, message: '请输入土壤湿度饱和阈值', trigger: 'blur' },
      { validator: integerWithin, trigger: ['blur', 'change'] }
    ], // 土壤湿度饱和阈值/%
    frozen: [
      { required: true, message: '请输入土壤温度冰冻阈值', trigger: 'blur' },
      { validator: validNumberOne, trigger: ['blur', 'change'] }
    ], // 土壤温度冰冻阈值/℃
    overheated: [
      { required: true, message: '请输入土壤温度过热阈值', trigger: 'blur' },
      { validator: validNumberOne, trigger: ['blur', 'change'] }
    ], // 土壤温度过热阈值/℃
    nitrogenLowerLimit: [
      { required: true, message: '请输入氮含量偏低阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    nitrogenUpperLimit: [
      { required: true, message: '请输入氮含量偏高阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    phosphorusLowerLimit: [
      { required: true, message: '请输入磷含量偏低阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    phosphorusUpperLimit: [
      { required: true, message: '请输入磷含量偏高阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    potassiumLowerLimit: [
      { required: true, message: '请输入钾含量偏低阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    potassiumUpperLimit: [
      { required: true, message: '请输入钾含量偏高阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    orpLowerLimit: [
      { required: true, message: '请输入ORP值偏低阈值', trigger: 'blur' },
      { validator: validNumberFour, trigger: ['blur', 'change'] }
    ],
    orpUpperLimit: [
      { required: true, message: '请输入ORP值偏高阈值', trigger: 'blur' },
      { validator: validNumberFour, trigger: ['blur', 'change'] }
    ]
  }

  created () {
    this.deviceForm.objectId = this.deviceId
    this.loadData()
    this.loadSpecies()
  }

  loadData () {
    this.$axios.get(this.$apis.devices.getDeviceConfig, {
      deviceId: this.deviceId
    }).then(res => {
      if (res === null) {
        this.loadType()
      } else if (res !== null && res.types === null) {
        this.deviceForm = res
        this.deviceForm.typeStr = ''
        this.deviceForm.types = []
        this.loadType()
      } else {
        this.deviceForm = res
        this.arr = this.deviceForm.typeStr.split(';')
        this.arr.forEach((item, index) => {
          if (item === '') {
            this.arr.splice(index, 1)
          }
        })
      }
    })
  }

  loadSpecies () {
    this.$axios.get<Species[]>(this.$apis.devices.getSpecies).then(res => {
      this.species = res
    })
  }

  loadType () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, { dicType: 'soilWithType' }).then(res => {
      res.soilWithType.forEach((item, index) => {
        if (index < 4) {
          this.arr.push(item.dicValue)
          this.deviceForm.types.push({
            name: item.dicValue,
            isSelect: '0'
          })
        } else {
          this.deviceForm.types.push({
            name: item.dicValue,
            isSelect: '1'
          })
        }
      })
    })
  }

  submit () {
    this.form.validate(valid => {
      if (valid) {
        this.deviceForm.typeStr = this.arr.join(';')
        this.loading = true
        this.$axios.post(this.$apis.devices.setDeviceConfig, this.deviceForm).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
        }).finally(() => {
          this.loading = false
        })
      }
    })
  }

  checkChange (bol: boolean, num: number) {
    if (bol) {
      this.deviceForm.types[num].isSelect = '0'
    } else {
      this.deviceForm.types[num].isSelect = '1'
    }
  }
}
