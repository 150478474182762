
import { Component, Vue } from 'vue-property-decorator'
import { DicInfo } from '../../types/common'
import { InsectInfo } from '../../types/devices'

@Component
export default class WaterConfig extends Vue {
  private submitShow = false
  private loading = false
  private funtStatus = '1'

  private deviceForm: Array<InsectInfo> = []
  private currentReport: Array<string> = []
  private reportList: Array<DicInfo> = []
  private enableList: Array<DicInfo> = []

  get deviceId () {
    return this.$route.params.deviceId as string || ''
  }

  created () {
    this.getReportList()
    this.getEnableList()
    this.deviceId && this.getDetail()
  }

  getReportList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'eventReport' }).then(res => {
      this.reportList = res.eventReport
      this.reportList.forEach(item => {
        item.isActive = true
      })
    })
  }

  getEnableList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'functionEnable' }).then(res => {
      res.functionEnable.forEach((item: DicInfo) => {
        this.enableList.push(item)
      })
      console.log(this.enableList)
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.devices.selectInsectConfigByList, {
      deviceId: this.deviceId
    }).then(res => {
      if (res.total > 0) {
        this.currentReport = []
        res.list.forEach((item: any) => {
          if (item.type === '1') {
            this.reportList.forEach((items, index) => {
              if (item.typeCode === items.dicCode) {
                this.currentReport.push((index + 1).toString())
              }
            })
          }
          if (item.type === '2') {
            this.funtStatus = item.typeCode
          }
        })
      }
    })
  }

  submit () {
    console.log(this.funtStatus)
    this.loading = true
    this.deviceForm = []
    this.currentReport.forEach(item => {
      this.deviceForm.push({
        deviceId: this.deviceId,
        type: '1',
        typeCode: this.reportList[Number(item) - 1].dicCode
      })
    })
    this.deviceForm.push({
      deviceId: this.deviceId,
      type: '2',
      typeCode: this.funtStatus
    })
    this.$axios.post(this.$apis.devices.insertUpdateAllInsectConfig, this.deviceForm).then(() => {
      this.$message.success('保存成功')
      this.getDetail()
    }).finally(() => {
      this.loading = false
    })
  }
}
