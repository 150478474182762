
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class SoilFertilityConfig extends Vue {
  @Prop() readonly deviceId!: string;
  @Ref('form') readonly form!: ElForm;

  loading = false
  projectId = ''
  deviceForm = {
    objectId: '',
    nitrogenLowerLimit: '',
    nitrogenUpperLimit: '',
    phosphorusLowerLimit: '',
    phosphorusUpperLimit: '',
    potassiumLowerLimit: '',
    potassiumUpperLimit: ''
  }

  rules = {
    nitrogenLowerLimit: [{ required: true, message: '请输入氮含量', trigger: 'blur' }], // 氮含量偏低
    nitrogenUpperLimit: [{ required: true, message: '请输入氮含量', trigger: 'blur' }], // 氮含量偏高
    phosphorusLowerLimit: [{ required: true, message: '请输入磷含量', trigger: 'blur' }], // 磷含量偏低
    phosphorusUpperLimit: [{ required: true, message: '请输入磷含量', trigger: 'blur' }], // 磷含量偏高
    potassiumLowerLimit: [{ required: true, message: '请输入钾含量', trigger: 'blur' }], // 钾含量偏低
    potassiumUpperLimit: [{ required: true, message: '请输入钾含量', trigger: 'blur' }] // 钾含量偏高
  }

  created () {
    this.deviceForm.objectId = this.deviceId
    this.loadData()
  }

  loadData () {
    this.$axios.get(this.$apis.devices.getFertilityConfig, {
      deviceId: this.deviceId
    }).then(res => {
      console.log(111, res)
      res && (this.deviceForm = res)
    })
  }

  submit () {
    this.form.validate(valid => {
      if (valid) {
        this.loading = true
        this.$axios.post(this.$apis.devices.updateFertilityConfig, this.deviceForm).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
        }).finally(() => {
          this.loading = false
        })
      }
    })
  }
}
