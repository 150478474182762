
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { DicInfo, DicList } from '../../types/common'
import { FormData } from '@/types/devices'

@Component
export default class MonitoringConfig extends Vue {
  @Prop() readonly deviceId!: string
  @Ref('formData') readonly form!: ElForm

  loading = false

  formData: FormData = {
    configList: [],
    objectId: '',
    channelId: '',
    addressLive: '',
    addressHd: '',
    rtmpLive: '',
    rtmpHd: '',
    ezopenRec: '',
    ezopenHdLive: '',
    ezopenLive: ''
  }

  currentEvent: Array<string> = []
  eventList: DicInfo[] = []

  rules = {
    channelId: [
      { required: true, message: '请输入设备视频通道id', trigger: 'blur' }
    ],
    addressLive: [
      { required: true, message: '请输入HLS流畅直播地址', trigger: 'blur' }
    ],
    addressHd: [
      { required: true, message: '请输入HLS高清直播地址', trigger: 'blur' }
    ],
    rtmpLive: [
      { required: true, message: '请输入PTMP流畅直播地址', trigger: 'blur' }
    ],
    rtmpHd: [
      { required: true, message: '请输入PTMP高清直播地址', trigger: 'blur' }
    ],
    ezopenRec: [
      { required: true, message: '请输入萤石云监控回放地址', trigger: 'blur' }
    ],
    ezopenHdLive: [
      { required: true, message: '请输入萤石云监控高清地址', trigger: 'blur' }
    ],
    ezopenLive: [
      { required: true, message: '请输入萤石云监控流畅地址', trigger: 'blur' }
    ]
  }

  async created () {
    this.loading = true
    const res = await this.getEventData()
    if (res && res.cameraConfig) {
      this.eventList = res.cameraConfig
    }
    this.getData()
  }

  getEventData () {
    return this.$axios.get<DicList>(this.$apis.common.selectDicByList, {
      dicType: 'cameraConfig'
    })
  }

  getData () {
    this.$axios
      .get(this.$apis.devices.getHistoryByTypeId('1002'), {
        deviceId: this.deviceId
      })
      .then((res) => {
        if (res) {
          this.formData = res
          this.formData.objectId = this.formData.objectId ? this.formData.objectId : '1'

          if (this.formData.configList && this.formData.configList.length > 0) {
            const arr: Array<string> = []
            this.formData.configList.forEach((item) => {
              arr.push(item.eventType)
            })
            this.currentEvent = arr.filter((item: string) => {
              return this.eventList.find((item2: any) => item === item2.dicCode)
            })
          }
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  saveData () {
    this.form.validate((valid) => {
      if (valid) {
        this.loading = true
        const configList: Array<any> = []
        if (this.currentEvent.length > 0) {
          this.currentEvent.forEach((item) => {
            configList.push({ eventType: item })
          })
        }
        this.formData.configList = configList

        this.$axios
          .post(this.$apis.devices.insertUpdateCamera, this.formData)
          .then((res) => {
            this.$message.success('保存成功')
            this.getData()
          })
          .finally(() => {
            this.loading = false
          })
      }
    })
  }
}
