
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { twoDecimal } from '@/utils/validate'
import { ManholeInfo } from '@/types/devices'

@Component
export default class ManholeConfig extends Vue {
  private info: ManholeInfo= {
    thresholdX: '',
    thresholdY: '',
    thresholdZ: '',
    thresholdVoltage: '',
    deviceNumber: '',
    thresholdLiquid: '',
    thresholdGas: '',
    deviceId: ''
  }

  private rules = {
    thresholdX: [
      { required: true, trigger: 'change', message: '请输入X轴偏移阈值（度）' },
      { trigger: 'change', validator: twoDecimal }
    ],
    thresholdY: [
      { required: true, message: '请输入Y轴偏移阈值', trigger: 'change' },
      { trigger: 'change', validator: twoDecimal }
    ],
    thresholdZ: [
      { required: true, message: '请输入Z轴偏移阈值', trigger: 'change' },
      { trigger: 'change', validator: twoDecimal }
    ],
    thresholdVoltage: [
      { required: true, message: '请输入电压告警阈值', trigger: 'change' },
      { trigger: 'change', validator: twoDecimal }
    ],
    thresholdLiquid: [
      { required: true, message: '请输入液位告警阈值', trigger: 'change' },
      { trigger: 'change', validator: twoDecimal }
    ],
    thresholdGas: [
      { required: true, message: '请输入气体告警阈值', trigger: 'change' },
      { trigger: 'change', validator: twoDecimal }
    ]
  }

  private submitShow = false

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.devices.selectManholeConfById, {
      deviceId: this.$route.params.deviceId
    }).then(res => {
      this.info = res || {}
    })
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.info.deviceId = this.$route.params.deviceId
        this.info.deviceNumber = this.$route.query.number
        this.$axios.post(this.$apis.devices.insertManholeConf, this.info).then(() => {
          this.$message.success('保存成功')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
