
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { DicInfo, DicList } from '../../types/common'
import { TrashConfig } from '../../types/devices'

@Component
export default class Config extends Vue {
  @Prop() readonly deviceId!: string;
  @Ref('form') readonly form!: ElForm;

  loading = false
  deviceForm: TrashConfig = {
    deviceId: '',
    trashcan1Type: '',
    trashcan2Type: '',
    trashcan3Type: '',
    trashcan4Type: '',
    trashcan5Type: '',
    trashcan6Type: ''
  }

  dicList: DicInfo[] = []

  created () {
    this.deviceForm.deviceId = this.deviceId
    this.loadData()
    this.loadTrashcanType()
  }

  loadData () {
    this.$axios.get(this.$apis.devices.selectTrashcanConfigById, {
      deviceId: this.deviceId
    }).then(res => {
      res && (this.deviceForm = res)
    })
  }

  loadTrashcanType () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, { dicType: 'trashcanType' }).then(res => {
      this.dicList = res.trashcanType
    })
  }

  submit () {
    this.form.validate(valid => {
      if (valid) {
        this.loading = true
        const url = this.deviceForm.id ? this.$apis.devices.updateTrashcanConfig : this.$apis.devices.insertTrashcanConfig
        this.$axios.post(url, this.deviceForm).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.loadData()
        }).finally(() => {
          this.loading = false
        })
      }
    })
  }
}
