
import { Vue, Component, Prop } from 'vue-property-decorator'

import SoilFertilityConfig from '@/views/device/SoilFertilityConfig.vue'
import SoilHumidityConfig from '@/views/device/SoilHumidityConfig.vue'
import TrashConfig from '@/views/device/TrashConfig.vue'
import MonitoringConfig from '@/views/device/MonitoringConfig.vue'
import InsectConfig from '@/views/device/InsectConfig.vue'
import DumpConfig from '@/views/device/DumpConfig.vue'
import ManholeConfig from '@/views/device/ManholeConfig.vue'
import WaterConfig from '@/views/device/WaterConfig.vue'
@Component({
  components: {
    SoilFertilityConfig,
    SoilHumidityConfig,
    TrashConfig,
    MonitoringConfig,
    InsectConfig,
    DumpConfig,
    ManholeConfig,
    WaterConfig
  }
})
export default class Config extends Vue {
  @Prop() readonly deviceId!: string;
  private config = ''
  private typeid = '' // 1021是倒伏监测设备

  created () {
    this.config = this.$route.query.config as string
    this.typeid = this.$route.query.typeid as string
  }
}
